import { graphql } from "gatsby"
import React, { useState, useEffect  } from "react"
import { Container } from "react-bootstrap"
import AreaGuideLanding from "../components/AreaGuideLanding/AreaGuideLanding"
import BreadcrumbModule from "../components/BreadcrumbModule/BreadcrumbModule"
import { formTracking } from "../components/Common/utils"
import LandingStaticCard from "../components/LandingStaticCard/LandingStaticCard"
import MarketReportListing from "../components/MarketReportListing/MarketReportListing"
import SocialShareComponent from "../components/SocialShareComponent/SocialShareComponent"
import Layout from "../components/layout"
import SEO from "../components/seo"
import qs from "qs"
import logoBlack from "../images/logo-white.svg"
import { removeDataAttributes } from "../components/Common/utils"


const MarketReportLandingTemplate = props => {
  const data = props.data
  const pageContext = props.pageContext
  // const pageData = data?.strapiPage
  const [pageData, setPageData] = useState(data?.strapiPage)
  const [loading,setLoading] = useState(true)
  const [isPreviewEnabled, setIsPreviewEnabled]=useState(false)

  const [enablePreview, setEnablePreview] = useState(pageContext?.strapi_preview == "true" ? true : false)


  const marketReportData = data?.allStrapiMarketReport?.edges
  // const areaData = data?.allStrapiArea?.edges
  const partData = data?.allStrapiQuarter?.edges

  const sortMarketReport = marketReportData?.sort(
    (a, b) => parseInt(a.node.sort) - parseInt(b.node.sort)
  )
  const areaList = []
  sortMarketReport?.map(item=>{
    if(item.node?.areas?.length>0){
      item.node?.areas?.forEach(a=>areaList.push(a))
    }
  })
  const uniqueArea={}
  const areaData = areaList.filter(item=>{
    if(!uniqueArea[item?.slug]){
      uniqueArea[item?.slug]=true
      return true
    }
    return false

  })
  const [shareIcons, setShareIcons] = useState(false)

  const openShareicons = () => {
    setShareIcons(true)
    if (shareIcons === true) {
      setShareIcons(false)
    }
    formTracking({
      event_tracking: "share-property",
      form_name: "Share Property ",
    })
  }

  let popUpData=""

  if(pageData?.add_page_modules?.length>0){
     const results=pageData?.add_page_modules?.filter(item=>item.__typename==="STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT")
     if(results?.length>0){
      popUpData=results[0]
     }
  }

  const query = qs.stringify(
    {
      populate: {
        ggfx_results:"*",
        choose_menu: "*",
        select_popular_search:"*",
        
        add_page_modules: {
          on: {
            "components.title-desc":{
              populate:"*"
            },
            "page-modules.static-card-items":{
              populate:{
                cards:{
                  populate:{
                    cta:{
                      populate:{
                        link: {
                          populate:{
                            parent:"*"
                          }
                        },
                        image:"*"
                      }
                    }
                  }
             }

          }
        }
          },
        },
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );

  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const isPreview = urlParams.get('debug') === 'preview';
    const apiUrl=`${process.env.GATSBY_STRAPI_SRC}/api/pages/${urlParams.get('strapi_id')}?${query}`

    // const apiUrl=process.env.GATSBY_STRAPI_SRC+'/api/events/'+urlParams.get('strapi_id')+'?populate[0]=modules&populate[1]=banner.cta_1_link&populate[2]=banner.cta_2_link&populate[3]=modules.icon_content&populate[4]=modules.icon_content.icon_module.image&populate[5]=form_content&populate[6]=ggfx_results&populate[12]=banner.image'
    const bearerToken = process.env.GATSBY_STRAPI_API_TOKEN;

    if(isPreview){
    setIsPreviewEnabled(true)
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl, {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        });
        const getData = await response.json();
        const previewData=removeDataAttributes(getData)
        setLoading(false)
        setEnablePreview(false)

        let myPrevData;
        if(pageData){
          myPrevData = pageData
        } else {
          myPrevData = {};
          myPrevData.add_page_modules=[]
          myPrevData.id = urlParams.get('strapi_id')
        }

        if(previewData){
          
          myPrevData.imagetransforms = previewData?.imagetransforms
          myPrevData.title = previewData?.title
          myPrevData.layout = previewData?.layout
          myPrevData.select_popular_search = previewData.select_popular_search
          myPrevData.choose_menu = previewData?.choose_menu

          myPrevData.ggfx_results = previewData?.ggfx_results
          myPrevData.add_page_modules=previewData?.add_page_modules
          setPageData(pageData => ({ ...pageData, myPrevData }));
        }
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }

    };
    fetchData();
    }
    else{
      setLoading(false)
      setIsPreviewEnabled(false)
    }

  },[])

  let urlParamsVal = ""
  let isPreviewVal = false

  if(typeof window !== "undefined"){
     urlParamsVal = new URLSearchParams(window.location.search);
    isPreviewVal = urlParamsVal.get('debug') === 'preview';
  }

  if(isPreviewVal){
    if(loading){
      return (
        <section className={"loader-wrapper"}>
          <div id="loader-wrapper">
            <div id="loader" class="new-loader">
              <img
                className="logo-white loader-logo"
                src={logoBlack}
                alt="logo"
              />
            </div>
          </div>
        </section>
      )
    }
  }

  if(enablePreview){
    return null
  }

  const pageurl = typeof window !== "undefined" ? window.location.href : ""

  return (
    <Layout popUpData={popUpData} dark={true} popularSearch={pageData?.select_popular_search?.title}>
      <div className="layout-padding-top"></div>
      
      <Container>
        <div className="breadcum-share-wrapper">
          <BreadcrumbModule
            choose_menu={pageData?.choose_menu}
            pagename={pageData?.title}
          />
          <div className="share-news-details" onClick={() => openShareicons()}>
            <i className="icon grey-share" />
            <span>Share</span>
            {shareIcons && (
              <SocialShareComponent
                openShareicons={openShareicons}
                shareurl={pageurl}
              />
            )}
          </div>
        </div>
      </Container>

      {pageData?.add_page_modules?.map((item, i) => {
        return (
          <div>
            {(item?.__typename === "STRAPI__COMPONENT_COMPONENTS_TITLE_DESC" ||
            item?.__component === "components.title-desc")
            && (
              <AreaGuideLanding isPreviewEnabled={isPreviewEnabled} pageData={item} />
            )}
          </div>
        )
      })}
      <MarketReportListing
        marketReportData={sortMarketReport}
        strapi_id={pageData?.strapi_id}
        add_page_modules={pageData?.add_page_modules}
        partData={partData}
        areaData={areaData}
        isPreviewEnabled={isPreviewEnabled}
      />
      {pageData?.add_page_modules?.map((item, i) => {
        return (
          <div>
            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_STATIC_CARD_ITEMS" ||
              item?.__component === "page-modules.static-card-items")
              && (
              <LandingStaticCard  isPreviewEnabled={isPreviewEnabled}data={item} />
            )}
          </div>
        )
      })}
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage
  return (
      <SEO title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} 
      imageUrl="https://ggfx-handh3.s3.eu-west-2.amazonaws.com/i/file_7035ecdafe.jpg?updated_at=2024-07-08T11:28:34.283Z"

      />
  )
}


export default MarketReportLandingTemplate

export const query = graphql`
  query ($page_id: String) {
    allStrapiArea(filter: { publish: { eq: true } }) {
      edges {
        node {
          title
          slug
          publish
        }
      }
    }
    allStrapiQuarter(
      filter: { publish: { eq: true } }
      sort: { fields: sort, order: ASC }
      ) {
      edges {
        node {
          title
          slug
        }
      }
    }
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment

      add_page_modules {
        ... on STRAPI__COMPONENT_COMPONENTS_TITLE_DESC {
          __typename
          id
          description {
            data {
              description
            }
          }
          title
        }

        ... on STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT {
          __typename
          ...GlobalPopUpFragment
        }

        ... on STRAPI__COMPONENT_COMPONENTS_BADGE_GREEN {
          id
          title
          __typename
          content {
            data {
              content
            }
          }
          cta {
            title
            link {
              slug
            }
          }
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_STATIC_CARD_ITEMS {
          __typename
          id
          cards {
            title
            content {
              data {
                content
              }
            }
            cta {
              title
              custom_link
              link {
                slug
                strapi_parent {
                  slug
                }
              }
            }
          }
        }
      }
    }

    allStrapiMarketReport(filter: { publish: { eq: true } }) {
      edges {
        node {
          slug
          tail_image {
            url
            alternativeText
          }
          ggfx_results {
            src_cftle
            transforms {
              format
              url
              transform
            }
          }
          imagetransforms {
            tail_image_Transforms
          }
          publishedAt
          areas {
            title
            slug
          }
          quarter {
            title
            slug
          }
          title
          strapi_id
        }
      }
    }
  }
`
